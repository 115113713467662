// extracted by mini-css-extract-plugin
export var active = "startups-packages-module--active--guFOE";
export var animateCircle = "startups-packages-module--animate-circle--E3sE4";
export var bounce = "startups-packages-module--bounce--dj+-O";
export var checkmark = "startups-packages-module--checkmark--fTk0l";
export var checkmarkCell = "startups-packages-module--checkmarkCell--MDn9I";
export var ldsRing = "startups-packages-module--lds-ring--gvOyt";
export var tableBodyCell = "startups-packages-module--tableBodyCell--5Jeg9";
export var tableBodyTitle = "startups-packages-module--tableBodyTitle--saDOr";
export var tableButtons = "startups-packages-module--tableButtons--TwFBk";
export var tableDesktop = "startups-packages-module--tableDesktop--viCQ5";
export var tableHead = "startups-packages-module--tableHead--qSM1s";
export var tableMobile = "startups-packages-module--tableMobile--Rlvpv";
export var tableMobileBody = "startups-packages-module--tableMobileBody--8BR8i";
export var tableMobileButtons = "startups-packages-module--tableMobileButtons--o6It9";
export var tableMobileColumn = "startups-packages-module--tableMobileColumn--fRTUU";
export var tableMobileColumns = "startups-packages-module--tableMobileColumns--aOAtk";
export var tablePrice = "startups-packages-module--tablePrice--orPkY";