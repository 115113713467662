import Fade from 'react-reveal/Fade'
import React, { useState } from 'react'
import className from 'classnames'
import Section from '../../../common/SectionComponent/Section'
import * as style from './startups-specials.module.scss'
import bgImage from '../../../../../static/images/startups-bg.jpg'

const StartupsSpecials = ({ data, heading }) => {
  const { title, description } = heading
  const [active, setActive] = useState(0)
  const nextSlide = () => {
    const next = active === data.length - 1 ? 0 : active + 1
    setActive(next)
  }
  const prevSlide = () => {
    const prev = active === 0 ? data.length - 1 : active - 1
    setActive(prev)
  }
  return (
    <div
      className={style.wrap}
      style={{ backgroundImage: `url('${bgImage}')` }}
    >
      <Section newClass={style.background} title={title}>
        <Fade bottom>
          <p className="text-color__gray--on-dark">{description}</p>
        </Fade>
        <div className={style.items}>
          {data.map((e, i) => (
            <div
              className={className(
                i === active && style.activeItem,
                style.item
              )}
            >
              <div className={style.buttonWrap}>
                <button
                  aria-label="Prevision slide"
                  type="button"
                  onClick={prevSlide}
                  onKeyDown={prevSlide}
                  className={style.button}
                />
                <h3 className={style.heading}>{e.title}</h3>
                <button
                  aria-label="Next slide"
                  type="button"
                  onClick={nextSlide}
                  onKeyDown={nextSlide}
                  className={style.button}
                />
              </div>

              <p className={className('text-color__gray--on-dark', style.text)}>
                {e.description}
              </p>
            </div>
          ))}
        </div>
      </Section>
    </div>
  )
}

export default StartupsSpecials
/*
<div style={{ backgroundImage: `url('${bgImage}')` }}> */
