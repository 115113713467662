import React from 'react'

import { graphql } from 'gatsby'
import MainLayout from '../../layouts/MainLayout'
import OneBusinessHeader from '../../components/Pages/Businesses/OneBusinessHeader/OneBusinessHeader'
import OneBusinessStages from '../../components/Pages/Businesses/OneBusinessStages/OneBusinessStages'
import OneBusinessSolutions from '../../components/Pages/Businesses/OneBusinessSolutions/OneBusinessSolutions'
import OneBusinessIndustry from '../../components/Pages/Businesses/OneBusinessIndustry/OneBusinessIndustry'
import StartupsSpecials from '../../components/Pages/Businesses/StartupsSpecials/StartupsSpecials'
import OneServicePortfolio from '../../components/Pages/OneService/OneServicePortfolio/OneServicePortfolio'
import MainClients from '../../components/Pages/main/MainClients/MainClients'
import OneServiceBlog from '../../components/Pages/OneService/OneServiceBlog/OneServiceBlog'
import StartupsPackages from '../../components/Pages/Businesses/StartupsPackages/StartupsPackages'

const BusinessesStartups = ({ data, location }) => {
  const { page_startups: pageData, metaTags } = data.wpPage
  const {
    startupsSolutionHeading,
    startupsSolutionsItems,
    startupsIndustry,
    startupsIndustryHeading,
    startupsSpecialsHeading,
    startupsSpecialsItem,
    startupsPortfolioButton,
    startupsPortfolioHeading,
    startupsPortfolioItems,
    startupsClientsSection,
    blogPosts,
    blogHeader,
    startupsPackagesHeading,
    packageItems,
    packageElements,
  } = pageData
  return (
    <MainLayout seoData={metaTags} location={location}>
      <OneBusinessHeader
        data={pageData}
        button={pageData.startupsHeaderButton}
      />
      <OneBusinessStages data={pageData.stagesSection} />
      <StartupsSpecials
        data={startupsSpecialsItem}
        heading={startupsSpecialsHeading}
      />
      <OneBusinessSolutions
        headingData={startupsSolutionHeading}
        items={startupsSolutionsItems}
      />
      <OneBusinessIndustry
        heading={startupsIndustryHeading}
        data={startupsIndustry}
      />
      <OneServicePortfolio
        title={startupsPortfolioHeading.title}
        description={startupsPortfolioHeading.description}
        buttonData={startupsPortfolioButton}
        portfolioItems={startupsPortfolioItems}
      />
      <StartupsPackages
        data={{
          packageItems,
          packageElements,
        }}
        heading={startupsPackagesHeading}
      />
      <MainClients
        isLight
        clientItems={startupsClientsSection.clientItems}
        heading={startupsClientsSection.clientsHeader}
      />
      <OneServiceBlog data={blogPosts} heading={blogHeader} />
    </MainLayout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 62 }) {
      metaTags {
        seoTitle
        seoDescription
        seoKeywords
        ogImage {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              resize(width: 1024, height: 512) {
                src
              }
            }
          }
          title
        }
      }
      page_startups {
        headerImage {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        headerText
        stagesSection {
          stagesHeader {
            description
            title
          }
          stagesBusiness {
            ... on WpBusiness {
              business {
                stages {
                  description
                  title
                }
              }
            }
          }
        }
        startupsHeaderButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        startupsSolutionHeading {
          description
          title
        }
        startupsSolutionsItems {
          description
          title
        }
        startupsIndustryHeading {
          title
          description
        }
        startupsIndustry {
          button {
            text
            filterBy
            industry {
              slug
            }
          }
          industry {
            ... on WpIndustry {
              industry {
                title
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
              }
              slug
            }
          }
        }
        startupsSpecialsHeading {
          description
          title
        }
        startupsSpecialsItem {
          description
          title
        }
        startupsPortfolioButton {
          addQuery
          text
          query
          useCustomUrl
          urlCustom
          url {
            ... on WpPost {
              uri
            }
            ... on WpPage {
              uri
            }
          }
        }
        startupsPortfolioHeading {
          description
          title
        }
        startupsPortfolioItems {
          ... on WpPortfolio_item {
            uri
            portfolioItem {
              title
              buttonText
              embeddedHtml
              type
              image {
                sourceUrl
              }
              plateVideo {
                localFile {
                  url
                }
              }
            }
          }
          ... on WpPortfolio_item {
            portfolioItem {
              caseStudySections {
                ... on WpPortfolio_item_Portfolioitem_CaseStudySections_Client {
                  playbackButton {
                    text
                    embeddedHtml
                  }
                }
              }
            }
          }
        }
        startupsClientsSection {
          clientItems {
            ... on WpClient {
              client {
                title
                name
                description
                photo {
                  localFile {
                    url
                  }
                }
                country {
                  localFile {
                    childImageSharp {
                      resize(width: 24, height: 24) {
                        src
                      }
                    }
                  }
                  altText
                  title
                }
              }
            }
          }
          clientsHeader {
            description
            title
          }
        }
        blogHeader {
          description
          fieldGroupName
          title
        }
        blogPosts {
          ... on WpPost {
            uri
            link
            title
            blogPost {
              blogPrewiev
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              minutesToRead
              tags {
                name
              }
            }
            blogAuthors {
              nodes {
                name
                blogAuthor {
                  photo {
                    altText
                    localFile {
                      url
                    }
                  }
                }
              }
            }
          }
        }
        startupsPackagesHeading {
          description
          title
        }
        packageItems {
          packagesButton {
            addQuery
            text
            query
            useCustomUrl
            urlCustom
            url {
              ... on WpPost {
                uri
              }
              ... on WpPage {
                uri
              }
            }
          }
          price
          title
        }
        packageElements {
          title
          isInPackages
        }
      }
    }
  }
`

export default BusinessesStartups
