import * as React from 'react'
import Fade from 'react-reveal/Fade'
import className from 'classnames'
import { useState } from 'react'
import * as style from './startups-packages.module.scss'
import Section from '../../../common/SectionComponent/Section'
import ButtonPrimary from '../../../common/buttons/ButtonPrimary'

const StartupsPackages = ({ heading, data }) => {
  const [active, setActive] = useState(0)
  const { title, description } = heading
  const { packageItems, packageElements } = data
  return (
    <Section title={title}>
      <Fade bottom>
        <p className="text-color__gray--on-dark">{description}</p>
      </Fade>
      <div>
        <div className={style.tableDesktop}>
          <div className={style.tableHead}>
            <div />
            {packageItems.map((e) => (
              <h3>{e.title}</h3>
            ))}
          </div>
          <div>
            {packageElements.map((e) => (
              <div className={style.tableBodyCell}>
                <div className={style.tableBodyTitle}>{e.title}</div>
                {packageItems.map((item, index) => (
                  <div className={style.checkmarkCell}>
                    {e.isInPackages.includes(`${index + 1}`) && (
                      <div className={style.checkmark} />
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div>
            <div className={style.tableBodyCell}>
              <div className={style.tableBodyTitle}>Price</div>
              {packageItems.map((elem) => (
                <div className={style.tablePrice}>{elem.price}</div>
              ))}
            </div>
            <div className={style.tableButtons}>
              <div />
              {packageItems.map((elem) => (
                <div>
                  <ButtonPrimary buttonData={elem.packagesButton} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.tableMobile}>
          {packageItems.map((elem, i) => (
            <div
              onClick={() => setActive(i)}
              className={className(
                style.tableMobileColumn,
                i === active && style.active
              )}
            >
              <div className={style.tableHead}>
                <h3>{elem.title}</h3>
              </div>
              <div className={style.tableMobileColumns}>
                <div className={style.tableMobileBody}>
                  {packageElements.map((e) => (
                    <div className={style.tableBodyCell}>
                      <div className={style.tableBodyTitle}>{e.title}</div>
                      <div className={style.checkmarkCell}>
                        {e.isInPackages.includes(`${i + 1}`) && (
                          <div className={style.checkmark} />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className={style.tableBodyCell}>
                    <div className={style.tableBodyTitle}>Price</div>
                    <div className={style.tablePrice}>{elem.price}</div>
                  </div>
                  <div className={style.tableMobileButtons}>
                    <ButtonPrimary buttonData={elem.packagesButton} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Section>
  )
}

export default StartupsPackages
